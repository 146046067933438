import { AlertProps, createSvgIcon } from '@mui/material';
import BandoAlert from '@components/Alert';
import { JSX } from 'react/jsx-runtime';

const RouteIcon = createSvgIcon(
  <svg xmlns="http://www.w3.org/2000/svg">
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M19 15.18V7c0-2.21-1.79-4-4-4s-4 1.79-4 4v10c0 1.1-.9 2-2 2s-2-.9-2-2V8.82C8.16 8.4 9 7.3 9 6c0-1.66-1.34-3-3-3S3 4.34 3 6c0 1.3.84 2.4 2 2.82V17c0 2.21 1.79 4 4 4s4-1.79 4-4V7c0-1.1.9-2 2-2s2 .9 2 2v8.18c-1.16.41-2 1.51-2 2.82 0 1.66 1.34 3 3 3s3-1.34 3-3c0-1.3-.84-2.4-2-2.82"
    />
  </svg>,
  'RouteIcon',
);

export const RouteAlert = (
  props: JSX.IntrinsicAttributes & AlertProps & { title?: string; text: string },
) => <BandoAlert icon={<RouteIcon />} {...props} />;
