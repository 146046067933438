export default {
  errors: {
    general: 'An error occurred. Please try again.',
  },
  validation: {
    invalidAmount: 'Invalid Amount',
    onMinAmount: 'The minimum amount is $100.00 MXN',
    offMinAmount: 'The minimum amount $5.00 USD',
    onMaxAmount: 'The maximum amount is $200,000.00 MXN',
    offMaxAmount: 'The maximum amount is $10,000.00 USD',
    email: 'Invalid email',
  },
  address: {
    search: 'Search for an address',
    notFound: 'Address not found',
  },
  signin: {
    button: 'Sign in',
    title: 'Sign in',
    footer: "Don't have an account?",
  },
  signup: {
    button: 'Sign up',
    title: 'Sign up',
    footer: 'Already have an account?',
  },
  identification: {
    type: 'Identification type',
    passport: 'Passport',
    ine: 'INE',
    driverLicense: 'Driver License',
  },
  kyc: {
    verifyTitle: 'Validate your identity',
    verifyAddress: 'Validate your address',
    fields: {
      phone: 'Phone number',
      name: 'First name',
      lastName: 'Last name',
      rfc: 'RFC',
      street: 'Street',
      neighborhood: 'Neighborhood',
      state: 'State / Province',
      zip: 'Zip code',
      country: 'Country',
      document: 'Document number',
    },
    phone: 'Phone number is required',
    phoneInvalid: 'Invalid phone number',
    name: 'First name is required',
    lastName: 'Last name is required',
    rfc: 'RFC is required',
    rfcInvalid: 'Invalid RFC',
    street: 'Your street, locality, corner, reference, etc. goes in this field.',
    neighborhood: 'The neighborhood or municipality where you live goes in this field.',
    state: 'The state where you live goes in this field.',
    zip: 'The postal code of your address goes in this field.',
    country: 'The country is required.',
    document: 'The document number is required',
  },
  quote: {
    baseAmount: 'Amount',
    quoteCurrency: 'Currency',
    network: 'Network',
    operationType: 'Operation',
    deposit: 'Deposit',
    withdraw: 'Withdraw',
    getQuote: 'Get quote',
  },
  optIn: 'Notify me about the status of my transaction',
  acceptTerms: 'By creating an account, you agree to our Terms of Service and Privacy Policy',
  verifyButton: 'Verify',
};
