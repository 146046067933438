export default {
  errors: {
    general: 'Ocurrió un error',
  },
  validation: {
    invalidAmount: 'Monto Invalido',
    onMinAmount: 'El monto mínimo es de $100.00 MXN',
    offMinAmount: 'El monto mínimo es de $2.00 USD',
    onMaxAmount: 'El monto máximo es de $200,000 MXN',
    offMaxAmount: 'El monto máximo es de $10,000 USD',
    email: 'Correo inválido',
  },
  signin: {
    button: 'Ingresar',
    title: 'Ingresa a tu cuenta',
    footer: 'Crear una cuenta',
  },
  signup: {
    button: 'Crear cuenta',
    title: 'Crea tu cuenta',
    footer: 'Inicia sesión',
  },
  identification: {
    type: 'Tipo de identificación',
    passport: 'Passaporte',
    ine: 'INE',
    driverLicense: 'Licencia de conducir',
  },
  address: {
    search: 'Busca tu domicilio o llena los campos manualmente',
    notFound: 'Dirección no encontrada',
  },
  kyc: {
    verifyTitle: 'Valida tu identidad',
    verifyAddress: 'Valida tu dirección',
    fields: {
      phone: 'Número de teléfono',
      name: 'Nombre',
      lastName: 'Apellido',
      rfc: 'RFC',
      street: 'Calle',
      neighborhood: 'Colonia',
      state: 'Estado',
      zip: 'Código postal',
      country: 'País',
      document: 'Número de documento',
    },
    phone: 'El télefono es requerido',
    phoneInvalid: 'Número de teléfono inválido',
    name: 'El nombre es requerido',
    lastName: 'El apellido es requerido',
    rfc: 'RFC es requerido',
    rfcInvalid: 'RFC Inválido',
    street: 'Tu calle, localidad, esquina, referencia, etc va en este campo.',
    neighborhood: 'La colonia o municipio en donde vives va en este campo.',
    state: 'El estado en donde vives va en este campo.',
    zip: 'El código postal de tu domicilio va en este campo.',
    country: 'El país es requerido.',
    document: 'El número de documento es requerido',
  },
  quote: {
    baseAmount: 'Monto',
    quoteCurrency: 'Moneda',
    network: 'Red',
    operationType: 'Operación',
    deposit: 'Depositar',
    withdraw: 'Retirar',
    getQuote: 'Obtener cotización',
  },
  optIn: 'Notificarme acerca del estatus de mi transacción',
  acceptTerms:
    'Al crear una cuenta, aceptas nuestros Términos de Servicio y Política de Privacidad',
  verifyButton: 'Verificar',
};
